<template>
  <v-app-bar app ref="walletToolbar">
    <v-toolbar-title>Wallet</v-toolbar-title>

    <v-btn text>
      Balance: <strong>{{ userBalance }}</strong>
    </v-btn>

    <v-menu offset-y v-model="logoutMenu" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text>
          <v-icon>mdi-account-circle</v-icon>
          <span class="ml-2 username-box" v-bind="attrs" v-on="on">
            {{ userName }}
          </span>
          <v-btn 
            icon 
            @click.stop="logout" 
            class="ml-2"
          >
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </v-btn>
      </template>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { useDisplay } from "vuetify";
import { onBeforeUnmount } from "vue";
import axios from "axios";
import emitter from '@/eventBus';

export default {
  name: "WalletToolBar",
  data() {
    return {
      userName: "User", 
      userBalance: 0, 
      logoutMenu: false,
      loading: false,
      eventSource: null,
    };
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.name) {
      this.userName = user.name;
    }
    this.fetchUserBalance();
  },
  emits: ["toggle-drawer"],
  setup(_, { emit }) {
    const { smAndDown } = useDisplay();

    const toggleDrawer = () => {
      emit("toggle-drawer");
    };

    onBeforeUnmount(() => {
      if (this.eventSource) {
        this.eventSource.close();
      }
    });

    return {
      isSmAndDown: smAndDown,
      toggleDrawer,
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("userAuthenticated");
      document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
      localStorage.removeItem("username");
      this.$router.push("/login");
    },
    fetchUserBalance() {
      const userAuthToken = localStorage.getItem("userAuthenticated");
      const apiUrl = process.env.VUE_APP_API_URL;

      axios
        .get(`${apiUrl}/user/balance`, {
          headers: {
            Authorization: `Bearer ${userAuthToken}`,
          },
        })
        .then((response) => {
          this.userBalance = response.data.balance;
        })
        .catch((error) => {
          console.error("Error fetching user balance:", error);
          this.userBalance = "0";
        });
    },
    updateUserBalance(balance) {
      this.userBalance = balance;
    },
    connectToSSE() {
      const userAuthToken = localStorage.getItem("userAuthenticated");
      const apiUrl = process.env.VUE_APP_API_URL;

      this.eventSource = new EventSource(`${apiUrl}/user/updates`, {
        headers: {
          Authorization: `Bearer ${userAuthToken}`,
        },
      });

      this.eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.balance !== undefined) {
          this.userBalance = data.balance;
        }
      };

      this.eventSource.onerror = (error) => {
        console.error("SSE connection error:", error);
        this.eventSource.close();
        setTimeout(this.connectToSSE, 5000); 
      };
    },
  },
  created() {
   emitter.on('update-balance', (balance) => {
      this.updateUserBalance(balance);
    });
  },
};
</script>

<style scoped>
.v-app-bar {
  background-color: #1976d2;
  color: white;
}
.ml-2 {
  margin-left: 8px;
}
.username-box {
  cursor: pointer;
}
</style>
