<template>
  <v-app>
    <AdminToolbar />
    <v-main>
      <v-container>
        <h3>All Transactions</h3>
        <v-card outlined>
          <v-card-text>
          <div class="transaction-table-wrapper">
            <v-simple-table dense class="transaction-table">
              <template v-slot:default>
                <thead>
                  <tr>
                   <th>ID</th>
                    <th>Date</th>
                    <th>Sender</th>
                    <th>Recipient</th>
                    <th>Amount</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="transaction in transactions" :key="transaction.id">
                    <td>{{ transaction.id }}</td>
                    <td>{{ transaction.date }}</td>
                    <td>{{ transaction.sender }}</td>
                    <td>{{ transaction.recipient }}</td>
                    <td>{{ transaction.amount }}</td>
                    <td>{{ transaction.type }}</td>
                    <td>
                      <v-chip :color="getStatusColor(transaction.status)" dark>
                        {{ transaction.status }}
                      </v-chip>
                    </td>
                    <td>
                      <div v-if="transaction.status === 'pending'">
                        <v-btn 
                          color="green" 
                          small 
                          @click="updateTransactionStatus(transaction.id, 'completed')"
                        >
                          Approve
                        </v-btn>
                        <v-btn 
                          color="red" 
                          small 
                          @click="updateTransactionStatus(transaction.id, 'canceled')"
                        >
                          Cancel
                        </v-btn>
                      </div>
                      <!-- Empty Cell for Non-Pending Transactions -->
                      <div v-else></div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AdminToolbar from '../components/AdminToolbar.vue';
import axios from 'axios';

export default {
  name: 'AdminLayout',
  components: {
    AdminToolbar,
  },
  data() {
    return {
      transactions: [],
      socket: null,
    };
  },
  methods: {
    fetchTransactions() {
      const userAuthToken = localStorage.getItem('isAuthenticated');
      if (!userAuthToken) {
        alert('No token found. Please log in again.');
        this.$router.push('/admin/login');
        return;
      }

      const apiUrl = process.env.VUE_APP_API_URL;

      axios
        .get(`${apiUrl}/alltransactions`, {
          headers: {
            Authorization: `Bearer ${userAuthToken}`,
          },
        })
        .then((response) => {
          this.transactions = response.data.map((transaction) => ({
            id: transaction.id,
            date: new Date(transaction.created_at).toLocaleString(),
            sender: transaction.sender_username,
            recipient: transaction.receiver_username,
            amount: transaction.amount,
            type: transaction.type,
            status: transaction.status,
          }));
        })
        .catch((error) => {
          console.error('Error fetching transactions:', error);
        });
    },
    updateTransactionStatus(transactionId, status) {
      const apiUrl = process.env.VUE_APP_API_URL;

      axios
        .put(`${apiUrl}/transaction/${transactionId}/status`, { status })
        .then(() => {
          this.fetchTransactions(); 
        })
        .catch((error) => {
          console.error('Error updating transaction status:', error);
        });
    },
    getStatusColor(status) {
      switch (status) {
        case 'Approved':
          return 'green';
        case 'Pending':
          return 'blue';
        case 'Canceled':
          return 'red';
        default:

          return 'grey';
      }
    },
    connectWebSocket() {
      const wsUrl = process.env.VUE_APP_WEBSOCKET_URL;
      this.socket = new WebSocket(wsUrl);

      this.socket.onopen = () => {
        console.log('WebSocket connection established');
      };

      this.socket.onmessage = (event) => {
      const transactionData = JSON.parse(event.data);

      if (transactionData.status === 'pending') {
        this.transactions.unshift({
          id: transactionData.id,
          date: new Date(transactionData.created_at).toLocaleString(),
          sender: transactionData.sender_username,
          recipient: transactionData.receiver_username,
          amount: transactionData.amount,
          status: transactionData.status,
        });
      }

      if (transactionData.status === 'canceled' || transactionData.status === 'completed') {
      const index = this.transactions.findIndex((t) => t.id == transactionData.id);
      console.log(index);
        if (index !== -1) {
          this.transactions[index].status = transactionData.status;
        }
      }
    };

      this.socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    },
  },
  mounted() {
    this.fetchTransactions(); 
    this.connectWebSocket();
  },
};
</script>

<style>
.transaction-table {
  width: 100%;
  border-collapse: collapse;
}

.transaction-table th,
.transaction-table td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

.transaction-table th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.transaction-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.transaction-table tr:hover {
  background-color: #f1f1f1;
}

.transaction-table-wrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.transaction-table {
  min-width: 800px; 
}

@media (max-width: 600px) {
  .transaction-table {
    font-size: 12px;
  }

  .transaction-table th,
  .transaction-table td {
    padding: 4px;
  }
   .v-navigation-drawer {
    width: 100% !important;
    max-width: 100%;
  }
}
</style>